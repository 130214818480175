@tailwind base;
@tailwind components;
@tailwind utilities;

#header__general_infos-navbar ul li:hover svg {
    color : #dc661f;
}
.standardShadow{
    box-shadow: 17px 16px 0px 1px #30344d;
}

.standardShadowLight{
    box-shadow: 17px 16px 0px 1px #d9d6d2;
}
.lightShadow{
    box-shadow: 3px 3px 0px 1px #d9d6d2;
}

.offerShadow{
    box-shadow: 10px 8px 0px 1px #dc661f;
}
.offerShadowLight{
    box-shadow: 10px 8px 0px 1px #d9d6d2;
}

.orangeTextShadow {
    text-shadow: 1px 1px 1px #3c1905;
}